import { VariantProps } from 'class-variance-authority';
import { Button, buttonVariants } from '@/components/shadcn/button';
import { cn } from '@/utils/styles';

export interface MultipleButtons {
  title?: string;
  description?: string | JSX.Element;
  containerClassName?: string;
  buttons: {
    label: string;
    onClick: () => void;
    variant?: VariantProps<typeof buttonVariants>['variant'];
    size?: VariantProps<typeof buttonVariants>['size'];
  }[];
}

export function Default({
  title,
  description,
  buttons,
  containerClassName,
}: MultipleButtons) {
  return (
    <div
      className={cn(
        'flex w-[360px] items-center justify-between gap-6 rounded-md border bg-neutral-900 p-4',
        containerClassName,
      )}>
      <div className="flex flex-col gap-2">
        {title && <div className="font-bold">{title}</div>}
        {description && <div className="text-xs">{description}</div>}
      </div>
      <div className="flex flex-col justify-between gap-2">
        {buttons.map(({ label, onClick, variant, size }) => (
          <Button key={label} variant={variant} size={size} onClick={onClick}>
            {label}
          </Button>
        ))}
      </div>
    </div>
  );
}

{
  Default;
}

import { useEffect, useLayoutEffect } from 'react';
import { ApiErrorEnum } from '@/types/api';
import { ILandmark, IMapFeature } from '@/types/features';
import { IObjectDetection } from '@/types/features';
import { getObjectDetection } from '@/api/admin';
import { redirectTo } from './url';

export interface IErrorValidation {
  redirect?: {
    destination: string;
    permanent: boolean;
  };
  error?: string;
}

export const isObject = (target: any) => {
  return Object.prototype.toString.call(target) === '[object Object]';
};

export const isIterable = (object: any) =>
  object != null && typeof object[Symbol.iterator] === 'function';

export const sortObjectByKey = (unsortedObject: { [key: string]: any }) => {
  const sorted = Object.keys(unsortedObject)
    .sort()
    .reduce((obj: { [key: string]: any }, key: string) => {
      obj[key] = unsortedObject[key];
      return obj;
    }, {});

  return sorted;
};

// https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
export const formatBytes = (a: number, b = 2) => {
  if (0 === a) return '0 Bytes';
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    ' ' +
    ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][d]
  );
};

export const padOneZero = (a: number) => {
  if (a < 10) {
    return `0${a | 0}`;
  } else {
    return `${a | 0}`;
  }
};

export const formatContentDuration = (a: number) => {
  let x = Math.floor(a / 1000);
  const s = x % 60;
  x = Math.floor(x / 60);
  const m = x % 60;
  x = Math.floor(x / 60);
  const h = x;

  if (!h && !m) {
    return `0:${s}`;
  } else if (!h) {
    return `${m}:${padOneZero(s)}`;
  } else {
    return `${h}:${padOneZero(m)}:${padOneZero(s)}`;
  }
};

export const formatCollectionDuration = (a: number) => {
  let x = Math.floor(a / 1000);
  const s = x % 60;
  x = Math.floor(x / 60);
  const m = x % 60;
  x = Math.floor(x / 60);
  const h = x;

  let ret = `${s} seconds`;
  if (m || h) {
    ret = `${m} minutes, ${ret}`;
  }
  if (h) {
    ret = `${h} hours, ${ret}`;
  }

  return ret;
};

export const getHumanReadableMountLabel = (str: string) => {
  switch (str) {
    case 'driverSide':
      return 'Left Side Facing';
    case 'passengerSide':
      return 'Right Side Facing';
    case 'forwardOrBackward':
      return 'Front Facing';
    default:
      return 'N/A';
  }
};

export const getHumanReadableDistance = (distance: number) => {
  if (distance !== null && !isNaN(distance)) {
    return `${distance.toFixed(2)} Km`;
  } else {
    return 'N/A';
  }
};

export const getHumanReadableSpeed = (speed: number) => {
  if (speed !== null && !isNaN(speed)) {
    return `${(speed * 3600).toFixed(2)} Kmh`;
  } else {
    return 'N/A';
  }
};

export const getHumanReadableAltitude = (alt: number) => {
  return `${alt.toFixed(2)} meters`;
};

export const getHumanReadableArea = (area: number) => {
  return `${area.toFixed(2)} Sq. Km`;
};

export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  typeof window.document !== 'undefined' &&
  typeof window.document.createElement !== 'undefined'
);

export const useIsomorphicLayoutEffect = canUseDOM
  ? useLayoutEffect
  : useEffect;

export const compactString = (str: string) => {
  if (!str) {
    return '';
  }

  if (str.length > 18) {
    return `${str.slice(0, 12)}...`;
  }

  return str;
};

export const compactAddress = (address: string) => {
  if (!address) {
    return '';
  }

  return `${address.slice(0, 6)}...${address.slice(-6)}`;
};

export const waitFor = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

export const validateError = (
  error: any,
  redirectToUrl = '',
): IErrorValidation => {
  const destinationCaptcha = '/captcha?redirectTo=' + redirectToUrl;
  if (error?.message?.includes(ApiErrorEnum.TooManyRequests)) {
    return { error: ApiErrorEnum.TooManyRequests };
  } else if (error?.message?.includes(ApiErrorEnum.Captcha)) {
    if (canUseDOM) {
      redirectTo(destinationCaptcha);
    } else {
      return {
        redirect: {
          destination: destinationCaptcha,
          permanent: false,
        },
      };
    }
  }
  return {
    error: 'something went wrong',
  };
};

export const getRandomMessage = (N: number) => {
  const messages = [
    'Incredible work!',
    'Fantastic effort! Keep it up!',
    ':N: reviews! Your enthusiasm is contagious!',
    "You're on fire today! Keep the streak going!",
    ':N: reviews down! Your progress is inspiring.',
    'Outstanding performance!',
    'Brilliant! Keep it rising!',
    'Keep aiming higher!',
    'Great job on hitting :N: submissions!',
    "You've mastered the art of the game!",
    'Look at you go!',
    ':N: submissions! Your dedication is remarkable!',
    'Awesome, :N: submissions!',
    ':N: submissions!',
    'Amazing! You did :N: submissions already',
    ':N: submissions! Keep paving the way!',
    ':N: submissions, well done!',
    'Wow, :N: submissions!',
    "You're a game changer! Keep moving forward",
    'Great stuff! Keep this energy going!',
    'Keep this great energy flowing!',
    'Keep going! :N: submissions and still counting!',
    "Wow, you're blazing a trail!",
    ':N: submissions down! Your progress is remarkable.',
    ':N: submissions! Your game spirit is outstanding.',
    'Keep the ball rolling!',
    "Impressive! Let's see how far you can go!",
    'Fantastic! Keep the pace!',
    'Keep this winning streak going!',
    'Amazing progress with :N: submissions!',
    "You're smashing it with :N: submissions! Keep pushing!",
    "Stupendous work! Let's keep this streak alive!",
    "Excellent! Let's keep breaking your record!",
    ':N: submissions! Your progress is sky-rocketing!',
    ":N: submissions! You're unstoppable. Keep going!",
    'Keep this fantastic effort up!',
    'Exceptional! Continue this superb work!',
    ':N: submissions! Your persistence is impressive.',
    ":N: submissions and you're on top of the game!",
    'Keep challenging yourself!',
    'Well done on achieving :N: submissions!',
  ];

  const randomIndex = Math.floor(Math.random() * messages.length);
  return messages[randomIndex].replace(':N:', N.toString());
};

export const areArraysEqual = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }

  return true;
};

export const areObjectsEqual = (obj1: any, obj2: any): boolean => {
  if (obj1 === null || obj2 === null) {
    return obj1 === obj2;
  }

  if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
    if (Array.isArray(obj1) && Array.isArray(obj2)) {
      return areArraysEqual(obj1, obj2);
    }
    return obj1 === obj2;
  }

  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!keys2.includes(key)) {
      return false;
    }

    const val1 = obj1[key];
    const val2 = obj2[key];

    if (val1 === null && val2 === null) {
      continue; // Both are null, continue with the next property
    }

    if (Array.isArray(val1) && Array.isArray(val2)) {
      if (!areArraysEqual(val1, val2)) {
        return false;
      }
    } else if (typeof val1 === 'object' && typeof val2 === 'object') {
      if (!areObjectsEqual(val1, val2)) {
        return false;
      }
    } else if (val1 !== val2) {
      return false;
    }
  }
  return true;
};

export function throttleDebounce<T extends (...args: any[]) => any>(
  func: T,
  limit: number,
  debounceDelay: number,
): (...args: Parameters<T>) => void {
  let lastFunc: NodeJS.Timeout | null = null;
  let lastRan: number | null = null;
  let debounceTimer: NodeJS.Timeout | null = null;

  return (...args: Parameters<T>) => {
    if (!lastRan) {
      func(...args);
      lastRan = Date.now();
    } else {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      debounceTimer = setTimeout(() => {
        if (Date.now() - lastRan! >= limit) {
          func(...args);
          lastRan = Date.now();
        }
      }, debounceDelay);

      if (lastFunc) {
        clearTimeout(lastFunc);
      }
      lastFunc = setTimeout(
        () => {
          if (Date.now() - lastRan! >= limit) {
            func(...args);
            lastRan = Date.now();
          }
        },
        limit - (Date.now() - lastRan!),
      );
    }
  };
}

// https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript
export function stringToColor(str: string) {
  let hash = 0;
  str.split('').forEach(char => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let color = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    color += value.toString(16).padStart(2, '0');
  }
  return color;
}

export function getWithoutOcrOutput(obj: any) {
  if (!obj) {
    return obj;
  }
  if (obj.attributes && Object.keys(obj.attributes).includes('ocr_output')) {
    const { attributes, ...rest } = obj;
    const { ocr_output, ...restAttributes } = attributes;
    return { ...rest, attributes: restAttributes };
  }
  return obj;
}

export async function getObjectDetections(
  odIds: string[],
  callback: (ods: IObjectDetection[]) => any,
) {
  const ods = await Promise.all(
    odIds.map(async odId => {
      const od = await getObjectDetection(odId);
      return od.objectDetection;
    }),
  );
  callback(ods);
}

import { IUser } from '@/types/user';
import { getCookie } from '@/utils/session';

export const getCurrentOrganization = (user: IUser, cookies: string) => {
  let currentOrganization = null;
  const usernameAndOrganizationId = getCookie(cookies, 'beemaps.org') || '';
  const decodedString = decodeURIComponent(usernameAndOrganizationId);
  const [username, organizationId] = decodedString?.split(':') || [];
  const isUser = user.username === username;

  if (isUser && organizationId) {
    currentOrganization = organizationId;
  } else {
    if (user.organizations?.length) {
      currentOrganization = user.organizations[0]._id;
    }
  }

  return currentOrganization;
};

import { IUser } from '@/types/user';

let user: IUser | null = null;

const UserStore = {
  set: (usrObj: IUser | null) => (user = usrObj),
  get: () => user,
};

Object.freeze(UserStore);
export default UserStore;

export const getCookie = (cookies: string, target: string): string | null => {
  if (!cookies) {
    return null;
  }

  const cookieArr = cookies.split(';');
  for (const cookie of cookieArr) {
    const trimmed = cookie.trim();
    const [name, value] = trimmed.split('=');

    if (name === target) {
      return value || null;
    }
  }

  return null;
};

export const setCookie = (name: string, value: string, days: number) => {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = '; expires=' + date.toUTCString();
  }
  document.cookie = `${name}=${encodeURIComponent(value)}${expires}; path=/`;
};

import {
  useState,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { IUser } from '@/types/user';
import { setCookie } from '@/utils/session';

interface IContext {
  user: IUser | null;
  setUser: Dispatch<SetStateAction<IUser | null>>;
  updateCurrentOrganization: (organizationId: string) => void;
}

interface UserProps {
  children: React.ReactNode;
  user: IUser | null;
}

const UserContext = createContext<IContext | null>(null);

const UserProvider: React.FC<UserProps> = ({ children, user: initialUser }) => {
  const [user, setUser] = useState<IUser | null>(initialUser);

  const updateCurrentOrganization = (organizationId: string) => {
    setUser(prevUser => {
      if (!prevUser) return prevUser;

      const value = `${prevUser.username}:${organizationId}`;
      setCookie('beemaps.org', value, 365);

      return {
        ...prevUser,
        currentOrganization: organizationId,
      };
    });
  };

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        updateCurrentOrganization,
      }}>
      {children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within the UserProvider');
  }

  return context;
};

export { UserProvider, useUser };

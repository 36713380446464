import { ApiErrorEnum } from '@/types/api';

export async function apiCall(
  url: string,
  options: Record<string, any> = {},
  data: Record<string, any> = {},
  signal?: AbortSignal,
) {
  let hasBody = false;
  Object.keys(data).forEach((key: string) => {
    if (data[key] === undefined) {
      return;
    }

    hasBody = true;
  });

  const body = hasBody ? JSON.stringify(data) : undefined;
  const opts: Record<string, any> = {
    body,
    cache: 'no-cache',
    method: 'POST',
    mode: 'cors',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    signal,
    credentials: 'include',
  };
  Object.keys(options).forEach((key: string) => {
    // If the key is a plain object, we merge it instead of overwriting it
    if (Object.prototype.toString.call(opts[key]) === '[object Object]') {
      opts[key] = { ...opts[key], ...options[key] };
    } else {
      opts[key] = options[key];
    }
  });

  const resp = await fetch(url, opts);

  let json;

  if (resp.status === 200) {
    if (isResponseText(opts)) {
      return resp;
    }
    json = await resp.json();
  }

  if (resp.status === 403) {
    json = { error: ApiErrorEnum.Forbidden };
  }

  if (resp.status === 409) {
    json = { error: ApiErrorEnum.Captcha };
  }

  if (resp.status === 429) {
    json = {
      error: ApiErrorEnum.TooManyRequests,
    };
  }

  if (!resp.ok) {
    const contentType = resp.headers.get('content-type');
    if (contentType?.includes('application/json')) {
      json = await resp.json();
    }

    throw new Error(json?.error || `${resp.status} while fetching ${url}`);
  }

  return json;
}

function isResponseText(opts: Record<string, any>): boolean {
  return (
    opts['headers']['accept'] == 'application/text' ||
    opts['headers']['Content-type'] == 'text/csv'
  );
}

import { NextPageContext } from 'next';
import { apiCall } from '@/utils/api';

export interface ConnectBreezeWalletParams {
  sessionId: string;
  signature: string;
  solanaAddress: string;
}

export const getSession = async (req: NextPageContext['req']) => {
  try {
    const user = await apiCall(`${process.env.NEXT_PUBLIC_API}/session`, {
      method: 'GET',
      headers: {
        cookie: req?.headers.cookie,
      },
    });

    return user?.username ? user : null;
  } catch (error: unknown) {
    console.log('getSession: ', error);
    return null;
  }
};

export const getLoginSession = async () => {
  const LOGIN_SESSION_ROUTE = `/auth/loginsession`;

  try {
    const { loginSessionKey } = await apiCall(
      `${process.env.NEXT_PUBLIC_API}${LOGIN_SESSION_ROUTE}`,
      { method: 'POST' },
    );
    return loginSessionKey;
  } catch (error: unknown) {
    return { error };
  }
};

export const loginWithPhantom = async (
  publicKey: string,
  signature: string,
  query: string,
) => {
  const AUTH_PHANTOM_ROUTE = `/auth/phantom/${publicKey}${query}`;

  try {
    const { user } = await apiCall(
      `${process.env.NEXT_PUBLIC_API}${AUTH_PHANTOM_ROUTE}`,
      {
        method: 'GET',
        headers: { signature },
      },
    );
    return user;
  } catch (error: unknown) {
    return { error };
  }
};

export const retrieveBreezeUser = async ({
  sessionId,
  signature,
  solanaAddress,
}: ConnectBreezeWalletParams) => {
  const params = new URLSearchParams([
    ['sessionKey', sessionId],
    ['signature', signature],
    ['solanaAddress', solanaAddress],
  ]);

  const url = `${process.env.NEXT_PUBLIC_API}/auth/breeze/login?${params}`;
  try {
    const res = await apiCall(url, {
      method: 'POST',
    });
    return res;
  } catch (error: unknown) {
    return { error };
  }
};

export const postToBreeze = async (
  email: string,
  path?: string,
  redirectTo?: string,
) => {
  try {
    const params: {
      isWebsite: string;
      path?: string;
      redirectTo?: string;
    } = {
      isWebsite: 'true',
    };

    if (path) {
      params.path = path;
    }

    if (redirectTo) {
      params.redirectTo = redirectTo;
    }

    const queryParams = new URLSearchParams(params);

    const url = `${
      process.env.NEXT_PUBLIC_API
    }/auth/breeze/validateEmail?${queryParams.toString()}`;
    const res = await apiCall(
      url,
      {
        method: 'POST',
      },
      {
        userEmail: email,
      },
    );

    return res;
  } catch (error: unknown) {
    return { error };
  }
};

export const postToMagicLink = async (
  email: string,
  redirectTo: string = '/map',
) => {
  try {
    const url = `${process.env.NEXT_PUBLIC_API}/auth/email/magiclink`;
    const res = await apiCall(
      url,
      {
        method: 'POST',
      },
      {
        email,
        redirectTo,
      },
    );

    return res;
  } catch (error: unknown) {
    return { error };
  }
};

export const deleteUser = async () => {
  const url = `${process.env.NEXT_PUBLIC_API}/user/delete`;

  try {
    const response = await apiCall(url, {
      method: 'DELETE',
    });
    return response;
  } catch (error: unknown) {
    console.log('User could not be deleted out due to:', error);
    return { error };
  }
};
